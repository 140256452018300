<template>
  <page-header :showCloseModal="true" title="Dog Mirror | Mall Lol Pet">
  </page-header>

  <ion-content>
    <div id="ionball"></div>

    <!-- fab placed to the top start -->
    <ion-fab vertical="top" horizontal="start" slot="fixed">
      <ion-fab-button>
        <ion-icon :icon="exit"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <!-- fab placed to the top end -->
    <ion-fab vertical="top" horizontal="end" slot="fixed">
      <ion-fab-button>
        <ion-icon :icon="toggle"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <!-- fab placed to the bottom end -->
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button>
        <ion-icon :icon="archive"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    
    <!--<iframe src="/assets/aframe-ar.html"></iframe>-->
  </ion-content>
</template>

<script>
// Vue reactivity
import { defineComponent, ref } from 'vue';

// icons
import { close, toggle, exit, archive } from 'ionicons/icons';

// components
import { IonContent, IonButtons, IonButton, IonIcon,
        IonCard, IonCardTitle, IonCardHeader, IonCardContent, IonItem, IonLabel,
        IonFab, IonFabButton,
        IonSpinner, IonImg, modalController, onIonViewWillEnter, onIonViewDidEnter } from '@ionic/vue';

// composables
import { useI18n } from 'vue-i18n';
import { CameraPreview, CameraPreviewPictureOptions, CameraPreviewOptions, CameraPreviewDimensions } from '@awesome-cordova-plugins/camera-preview';

export default defineComponent({
  name: 'DogWalkingModal',
  props: [],
  components: { IonContent, IonButtons, IonButton, IonIcon,
                IonCard, IonCardTitle, IonCardHeader, IonCardContent, IonItem, IonLabel,
                IonFab, IonFabButton, IonImg, IonSpinner,
                },
  setup(props) {
    const { t } = useI18n();
    const closeModal = async () => { await modalController.dismiss() };

    const cameraPreviewOptions = {
      camera: 'front',
      toBack: true,
    };
    onIonViewDidEnter(() => {
      CameraPreview.startCamera(cameraPreviewOptions);
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close, toggle, exit, archive,

      // methods
      t, closeModal,
    }
  }
});
</script>

<style scoped>
  html, body, ion-app, ion-content, .ion-page, ion-header, ion-modal {
    --background: transparent !important;
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
  }

  #ionball {
    position: absolute;
    bottom: 30px;
    left: calc(50% - 50px);
    width: 100px;
    height: 100px;
    background-image: url('../../assets/ionball.png');
  }
</style>